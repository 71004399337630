import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Introduction to what this document is`}</em></p>
    <h2>{`Developing Components`}</h2>
    <p>{`Components should be:`}</p>
    <ul>
      <li parentName="ul">{`Flexible`}</li>
      <li parentName="ul">{`Comprehensive`}</li>
      <li parentName="ul">{`Consistent`}</li>
      <li parentName="ul">{`Encapsulated`}</li>
      <li parentName="ul">{`Efficient`}</li>
      <li parentName="ul">{`Compositional`}</li>
    </ul>
    <h3>{`Component Architecture`}</h3>
    <h3>{`Development Resources`}</h3>
    <h2>{`Developing Styles`}</h2>
    <h2>{`Naming Conventions`}</h2>
    <h3>{`Stylesheet`}</h3>
    <h3>{`Component Library`}</h3>
    <h4>{`Components`}</h4>
    <h4>{`Props`}</h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      